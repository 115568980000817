/* Header.css */

/* Animation for text flying in from the left */
.home-page .text-center {
  animation: fly-in-left 2s ease-out forwards; /* Slowed down for a smoother effect */
}

@keyframes fly-in-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Hover effect for text and image */
.image-hover-effect {
  transition: transform 0.3s ease-out;
}

.image-hover-effect:hover {
  transform: scale(1.05);
}

/* Additional styles */

.carousel .thumbs-wrapper,
.carousel-status {
  display: none;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.parent-loader {
  background-color: #043E52; /* Nile Blue */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.font-link {
  font-family: "Manrope", sans-serif;
}

.c-btn span:first-child {
  transform: translateX(-101%);
  transition: transform 0.3s ease-in;
}

.c-btn:hover span {
  transform: translateX(0);
}

.socials .footer-div span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
}

.socials span {
  transition: transform 0.2s ease-out;
}

.socials .footer-div:hover span:first-child {
  transform: translateY(100%);
}

.socials .footer-div:hover span:last-child {
  transform: none;
}

.landing-illustration {
  transform-origin: center;
  transform-style: preserve-3d;
  transform: scale(1);
  transition: transform 225ms ease-out;
}

.landing-illustration:hover {
  transform: scale(1) rotateX(30deg) rotateY(0deg);
  transition: transform 380ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 30px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media screen and (max-width: 768px) {
  .contact-btn {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  :placeholder-shown {
    font-size: 12px;
  }
}

@media screen and (min-width: 424px) and (max-width: 500px) {
  .submit-btn {
    top: 7%;
  }
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  20% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  60% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}

.animate-vibrate {
  animation: vibrate 0.3s linear infinite;
}

@keyframes vibrate {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

.hover\:animate-vibrate:hover {
  animation: vibrate 0.3s linear infinite;
}

/* Define the keyframes for the background animation */
@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Apply the animation to the background */
.bg-animation {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, var(--color-bg-gradient-1), var(--color-bg-gradient-2), var(--color-bg-gradient-3), var(--color-bg-gradient-4)); /* Use CSS variables for the gradient colors */
  background-size: 800% 800%;
  animation: backgroundAnimation 20s ease infinite;
  z-index: -1; /* Ensure it stays behind the content */
}

:root {
  --color-bg-gradient-1: #E16A3D; /* Sunset Orange */
  --color-bg-gradient-2: #FFA45D; /* Tangerine */
  --color-bg-gradient-3: #1C9290; /* Sea Green */
  --color-bg-gradient-4: #016A6D; /* Pine */
  --color-header-bg: #043E52; /* Nile Blue */
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.box {
  padding: 20px;
}

.text-orange {
  color:#E16A3D;
}
.text-orange:hover {
  color:white;
}

.text-white:hover {
  color: #E16A3D;
}